/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../models/API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const checkCardId = /* GraphQL */ `query CheckCardId($cardId: String!) {
  checkCardId(cardId: $cardId) {
    isAvailable
  }
}
` as GeneratedQuery<
  APITypes.CheckCardIdQueryVariables,
  APITypes.CheckCardIdQuery
>;
export const getCountries = /* GraphQL */ `query GetCountries {
  getCountries {
    countries {
      abbreviation
      id
      name
    }
  }
}
` as GeneratedQuery<
  APITypes.GetCountriesQueryVariables,
  APITypes.GetCountriesQuery
>;
export const getCustomer = /* GraphQL */ `query GetCustomer($id: ID!) {
  getCustomer(id: $id) {
    currency
    currentBudget
    dsmUnit {
      id
      name
    }
    id
    initialBudget
    locations {
      address {
        city
        country {
          abbreviation
          id
          name
        }
        number
        postalCode
        state
        street
      }
      avgNrOfAnimals
      contact {
        id
        name
        relation
      }
      customer {
        currency
        currentBudget
        dsmUnit {
          id
          name
        }
        id
        initialBudget
        locations {
          avgNrOfAnimals
          groups
          id
          name
          phone
        }
        name
      }
      groups
      id
      name
      phone
    }
    name
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerQueryVariables,
  APITypes.GetCustomerQuery
>;
export const getCustomersForUser = /* GraphQL */ `query GetCustomersForUser {
  getCustomersForUser {
    customers {
      currency
      currentBudget
      dsmUnitId
      id
      name
      numberOfFarms
      numberOfKits
      numberOfRequests
    }
  }
}
` as GeneratedQuery<
  APITypes.GetCustomersForUserQueryVariables,
  APITypes.GetCustomersForUserQuery
>;
export const getKitRequest = /* GraphQL */ `query GetKitRequest($kitRequestId: String!) {
  getKitRequest(kitRequestId: $kitRequestId) {
    kitRequest {
      address {
        city
        country {
          abbreviation
          id
          name
        }
        number
        postalCode
        state
        street
      }
      contactName
      id
      location {
        address {
          city
          number
          postalCode
          state
          street
        }
        avgNrOfAnimals
        contact {
          id
          name
          relation
        }
        groups
        id
        name
        phone
      }
      orders {
        amount
        createdDate
        id
        kit {
          currency
          id
          name
          priceEx
          vatPercentage
        }
        status
        trackAndTrace
        transportProvider
      }
      sentToLocation
    }
  }
}
` as GeneratedQuery<
  APITypes.GetKitRequestQueryVariables,
  APITypes.GetKitRequestQuery
>;
export const getKitRequestTable = /* GraphQL */ `query GetKitRequestTable($customerId: String, $input: KitRequestTableInput!) {
  getKitRequestTable(customerId: $customerId, input: $input) {
    itemsPerPage
    pages {
      page
      token
    }
    rows {
      createdDateTime
      customer {
        currency
        currentBudget
        dsmUnit {
          id
          name
        }
        id
        initialBudget
        locations {
          avgNrOfAnimals
          groups
          id
          name
          phone
        }
        name
      }
      kitRequestId
      locationName
      orderNumber
      status
      totalAmount
      trackAndTrace
    }
    sortBy
    sortDirection
  }
}
` as GeneratedQuery<
  APITypes.GetKitRequestTableQueryVariables,
  APITypes.GetKitRequestTableQuery
>;
export const getLocation = /* GraphQL */ `query GetLocation($locationId: String!) {
  getLocation(locationId: $locationId) {
    location {
      address {
        city
        country {
          abbreviation
          id
          name
        }
        number
        postalCode
        state
        street
      }
      avgNrOfAnimals
      contact {
        id
        name
        relation
      }
      customer {
        currency
        currentBudget
        dsmUnit {
          id
          name
        }
        id
        initialBudget
        locations {
          avgNrOfAnimals
          groups
          id
          name
          phone
        }
        name
      }
      groups
      id
      name
      phone
    }
  }
}
` as GeneratedQuery<
  APITypes.GetLocationQueryVariables,
  APITypes.GetLocationQuery
>;
export const getLocationsForCustomer = /* GraphQL */ `query GetLocationsForCustomer($customerId: String!) {
  getLocationsForCustomer(customerId: $customerId) {
    locations {
      address {
        city
        country {
          abbreviation
          id
          name
        }
        number
        postalCode
        state
        street
      }
      avgNrOfAnimals
      contact {
        id
        name
        relation
      }
      groups
      id
      name
      phone
    }
  }
}
` as GeneratedQuery<
  APITypes.GetLocationsForCustomerQueryVariables,
  APITypes.GetLocationsForCustomerQuery
>;
export const getNotificationsForCustomer = /* GraphQL */ `query GetNotificationsForCustomer($customerId: String!) {
  getNotificationsForCustomer(customerId: $customerId) {
    notifications {
      createdAt
      customer {
        currency
        currentBudget
        dsmUnit {
          id
          name
        }
        id
        initialBudget
        locations {
          avgNrOfAnimals
          groups
          id
          name
          phone
        }
        name
      }
      description
      id
      messageType
      readBy
      referenceId
      source
      status
      transportProvider
    }
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationsForCustomerQueryVariables,
  APITypes.GetNotificationsForCustomerQuery
>;
export const getPhaseForSpecies = /* GraphQL */ `query GetPhaseForSpecies($speciesId: String) {
  getPhaseForSpecies(speciesId: $speciesId) {
    phases {
      adequate
      deficient
      geneticLineage {
        id
        name
      }
      geneticSupplier {
        id
        name
      }
      id
      insufficient
      name
      optimum
      testReasonConfig
    }
  }
}
` as GeneratedQuery<
  APITypes.GetPhaseForSpeciesQueryVariables,
  APITypes.GetPhaseForSpeciesQuery
>;
export const getProductCompetitors = /* GraphQL */ `query GetProductCompetitors($customerId: String, $productTypeId: String) {
  getProductCompetitors(
    customerId: $customerId
    productTypeId: $productTypeId
  ) {
    competitors {
      customer {
        currency
        currentBudget
        dsmUnit {
          id
          name
        }
        id
        initialBudget
        locations {
          avgNrOfAnimals
          groups
          id
          name
          phone
        }
        name
      }
      id
      name
      productType {
        id
        name
      }
      sampleType {
        id
        name
        productType {
          id
          name
        }
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.GetProductCompetitorsQueryVariables,
  APITypes.GetProductCompetitorsQuery
>;
export const getSampleRequest = /* GraphQL */ `query GetSampleRequest($sampleRequestId: String!) {
  getSampleRequest(sampleRequestId: $sampleRequestId) {
    sampleRequest {
      average
      barn
      collectionDate
      comment
      d3Level
      housingSystem
      id
      location {
        address {
          city
          number
          postalCode
          state
          street
        }
        avgNrOfAnimals
        contact {
          id
          name
          relation
        }
        customer {
          currency
          currentBudget
          id
          initialBudget
          name
        }
        groups
        id
        name
        phone
      }
      name
      phase {
        adequate
        deficient
        geneticLineage {
          id
          name
        }
        geneticSupplier {
          id
          name
        }
        id
        insufficient
        name
        optimum
        testReasonConfig
      }
      phaseName
      problem
      problemArea
      productionSystem
      remarks
      samples {
        age
        analytes {
          id
          name
        }
        animalId
        cardId
        collectionDate
        comment
        costCenter {
          id
          name
        }
        dosage
        feedComposition {
          caLevel
          pAvailable
          pLevel
          phytase
          phytaseUnit
        }
        geneticLineage
        geneticSupplier
        id
        parity
        phase {
          adequate
          deficient
          id
          insufficient
          name
          optimum
          testReasonConfig
        }
        problemAnswer
        productCategory {
          id
          name
        }
        productType {
          id
          name
        }
        results {
          analysis
          analysisUnit
          evaluation
          expected
          id
          line
          module
          outlier
          repetitions
          result
          unit
        }
        sex
        sowStage
        species {
          enabled
          group
          id
          name
        }
        timeline {
          comments
          date
          description
          id
          order
          status
        }
      }
      specificPhase
      specificProblemArea
      takenBy
      testReason
      testReasonProblem
    }
  }
}
` as GeneratedQuery<
  APITypes.GetSampleRequestQueryVariables,
  APITypes.GetSampleRequestQuery
>;
export const getSampleResultCalculationsForCustomer = /* GraphQL */ `query GetSampleResultCalculationsForCustomer(
  $customerId: String!
  $phaseId: String
) {
  getSampleResultCalculationsForCustomer(
    customerId: $customerId
    phaseId: $phaseId
  ) {
    adequate
    availablePhases {
      id
      name
    }
    deficient
    insufficient
    optimum
    selectedPhase {
      adequate
      deficient
      geneticLineage {
        id
        name
      }
      geneticSupplier {
        id
        name
      }
      id
      insufficient
      name
      optimum
      testReasonConfig
    }
    totalAverage
  }
}
` as GeneratedQuery<
  APITypes.GetSampleResultCalculationsForCustomerQueryVariables,
  APITypes.GetSampleResultCalculationsForCustomerQuery
>;
export const getSampleResultCalculationsForLocation = /* GraphQL */ `query GetSampleResultCalculationsForLocation(
  $locationId: String!
  $phaseId: String
) {
  getSampleResultCalculationsForLocation(
    locationId: $locationId
    phaseId: $phaseId
  ) {
    adequate
    availablePhases {
      id
      name
    }
    deficient
    insufficient
    optimum
    selectedPhase {
      adequate
      deficient
      geneticLineage {
        id
        name
      }
      geneticSupplier {
        id
        name
      }
      id
      insufficient
      name
      optimum
      testReasonConfig
    }
    totalAverage
  }
}
` as GeneratedQuery<
  APITypes.GetSampleResultCalculationsForLocationQueryVariables,
  APITypes.GetSampleResultCalculationsForLocationQuery
>;
export const getSampleResultTableForCustomer = /* GraphQL */ `query GetSampleResultTableForCustomer(
  $customerId: String
  $input: SampleResultTableInput!
) {
  getSampleResultTableForCustomer(customerId: $customerId, input: $input) {
    itemsPerPage
    pages {
      page
      token
    }
    rows {
      average
      collectionDate
      customer {
        currency
        currentBudget
        dsmUnit {
          id
          name
        }
        id
        initialBudget
        locations {
          avgNrOfAnimals
          groups
          id
          name
          phone
        }
        name
      }
      description
      locationId
      locationName
      name
      phaseName
      sampleRequestId
      status
    }
    sortBy
    sortDirection
  }
}
` as GeneratedQuery<
  APITypes.GetSampleResultTableForCustomerQueryVariables,
  APITypes.GetSampleResultTableForCustomerQuery
>;
export const getSampleResultTableForLocation = /* GraphQL */ `query GetSampleResultTableForLocation(
  $input: SampleResultTableInput!
  $locationId: String!
) {
  getSampleResultTableForLocation(input: $input, locationId: $locationId) {
    itemsPerPage
    pages {
      page
      token
    }
    rows {
      average
      collectionDate
      customer {
        currency
        currentBudget
        dsmUnit {
          id
          name
        }
        id
        initialBudget
        locations {
          avgNrOfAnimals
          groups
          id
          name
          phone
        }
        name
      }
      description
      locationId
      locationName
      name
      phaseName
      sampleRequestId
      status
    }
    sortBy
    sortDirection
  }
}
` as GeneratedQuery<
  APITypes.GetSampleResultTableForLocationQueryVariables,
  APITypes.GetSampleResultTableForLocationQuery
>;
export const getSpecies = /* GraphQL */ `query GetSpecies($group: SpeciesGroup) {
  getSpecies(group: $group) {
    species {
      enabled
      group
      id
      name
    }
  }
}
` as GeneratedQuery<
APITypes.GetSpeciesQueryVariables,
APITypes.GetSpeciesQuery
>;

export const getAdminExport = /* GraphQL */ `query GetAdminExport {
  getAdminExport {
    url 
  }
}
` as GeneratedQuery<APITypes.GetAdminExportVariables, APITypes.GetAdminExport>;

