import { DsmSelectOption } from "@dsm-dcs/design-system";
import { DsmButton, DsmGrid, DsmIcon, DsmLoadingIndicator } from "@dsm-dcs/design-system-react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import PageHeader from "../../../components/pageHeader/PageHeader";
import { ContactType } from "../../../models/enums/contactType.enum";
import { AddFarmForm, getFarmFormSchema } from "../../../models/forms/add-farm-form.model";
import { routeTypes, routes } from "../../../routes";
import styles from "./FarmCreate.module.scss";
import { getCountriesForSelect } from "../../../services/metaData.service";
import { useLayout } from "../../../contexts/layout.context";
import { createLocation } from "../../../services/location.service";
import { SpeciesGroup } from "../../../models/API";
import { getCustomersForSelect, getUser } from "../../../services/user.service";
import Select from "../../../components/form/select/Select";
import Input from "../../../components/form/input/Input";
import RadioButtonGroup from "../../../components/form/radioButtonGroup/RadioButtonGroup";
import { AuthContext } from "../../../contexts/auth.context";
import { Role } from "../../../models/enums/role.enum";

function FarmCreate() {
  //Hooks
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setPageTitle, setCrumbs, setToast } = useLayout();
  const { customer, role } = useContext(AuthContext);
  const [schema] = useState(getFarmFormSchema());
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    resetField,
    formState: { errors }
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: {
      contactType: ContactType.Me,
      species: SpeciesGroup.Swine
    }
  });

  //State
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [customerOptions, setCustomerOptions] = useState<DsmSelectOption[]>([]);
  const [countryOptions, setCountryOptions] = useState<DsmSelectOption[]>([]);
  const [name, setName] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const contactType = watch("contactType");
  const [contactTypeOptions, setContactTypeOptions] = useState<DsmSelectOption[]>([]);

  //Effects
  useEffect(() => {
    setPageTitle(t("farm-create.page.title"));
    handleCrumbs();
    initData();
  }, []);

  useEffect(() => {
    if (contactType === ContactType.Me) {
      setValue("contactPerson", name);
    } else {
      setValue("contactPerson", "");
    }
    resetField("phone", { keepError: true });
  }, [contactType]);

  //Methods
  const initData = async () => {
    setContactTypeOptions(
      Object.keys(ContactType).map((contactType) => {
        return { value: contactType, text: t(`general.contact-type.${contactType.toLocaleLowerCase()}`) };
      })
    );
    await Promise.all([initCustomers(), initUser(), initCountries()]);
    setIsLoading(false);
  };

  const initCustomers = async () => {
    if (role !== Role.Manager && role !== Role.Admin) {
      setValue("customerId", customer?.id ?? "");
      return;
    }

    const customerOptions = await getCustomersForSelect(setToast);
    setCustomerOptions(customerOptions);
    if (customer?.id) {
      setValue("customerId", customer?.id ?? "");
    }
  };

  const initUser = async () => {
    const user = await getUser();
    setValue("contactPerson", user.fullName);
    setName(user.fullName);
  };

  const initCountries = async () => {
    const countryOptions = await getCountriesForSelect(setToast);
    setCountryOptions(countryOptions);
  };

  const handleCrumbs = () => {
    const startCrumbs = [];
    if (role === Role.Customer) {
      startCrumbs.push({ title: t("farms.page.title"), url: routes.farms, type: routeTypes.farms });
    }
    setCrumbs([...startCrumbs, { title: t("farm-create.page.title"), type: routeTypes.farmCreate }]);
  };

  const onSubmit = async (formData: AddFarmForm) => {
    setIsSubmitting(true);
    try {
      await createLocation(formData);
      setToast({ text: t("farm-create.submit-success"), type: "success" });
      navigate(routes.farms);
    } catch (e: unknown) {
      const error = e as Error;
      setIsSubmitting(false);
      setToast({ text: t("general.error", { action: t("farm-create.error-action") }), error: error.message });
    }
  };

  const handleCancel = () => navigate(routes.farms);

  return (
    <>
      <DsmGrid className="main-container main-container--with-breadcrumb" fixed={true} container-only={true}>
        <PageHeader header={t("farm-create.page.title")} description={t("farm-create.page.description")}></PageHeader>
        {!isLoading ? (
          <>
            <div className={styles.form}>
              <div className={styles.form__part}>
                <h4 className={styles.form__header}>{t("farm-create.info-header")}</h4>
                {role === Role.Manager || role === Role.Admin ? (
                  <Select
                    fieldName="customerId"
                    control={control}
                    options={customerOptions}
                    label={t("farm.customer")}
                    fieldError={errors.customerId}
                    schema={schema} //Cannot use schema because it is conditional
                    emptyText={t("general.no-options")}
                  ></Select>
                ) : null}
                <Input control={control} fieldName="name" label={t("farm.name")} fieldError={errors.name} schema={schema}></Input>
                <h4 className={styles.form__header}>{t("farm-create.address-header")}</h4>
                <div className={styles.form__row}>
                  <Input
                    control={control}
                    fieldName="street"
                    label={t("general.address.street")}
                    fieldError={errors.street}
                    schema={schema}
                  ></Input>
                  <Input
                    control={control}
                    fieldName="number"
                    label={t("general.address.number")}
                    fieldError={errors.number}
                    schema={schema}
                  ></Input>
                </div>
                <div className={styles.form__row}>
                  <Input
                    control={control}
                    fieldName="postalCode"
                    label={t("general.address.postal-code")}
                    fieldError={errors.postalCode}
                    schema={schema}
                  ></Input>
                  <Input
                    control={control}
                    fieldName="city"
                    label={t("general.address.city")}
                    fieldError={errors.city}
                    schema={schema}
                  ></Input>
                </div>
                <div className={styles.form__row}>
                  <Input
                    control={control}
                    fieldName="state"
                    label={t("general.address.state")}
                    fieldError={errors.state}
                    schema={schema}
                  ></Input>
                  <Select
                    fieldName="country"
                    control={control}
                    options={countryOptions}
                    label={t("general.address.country")}
                    fieldError={errors.country}
                    schema={schema}
                    useSearch={true}
                    emptyText={t("general.no-options")}
                  ></Select>
                </div>
              </div>
              <div className={styles.form__part}>
                <h4 className={styles.form__header}>{t("farm-create.contact-person-header")}</h4>
                <Input
                  control={control}
                  fieldName="contactPerson"
                  label={t("farm.contact-person")}
                  disabled={contactType === ContactType.Me}
                  fieldError={errors.contactPerson}
                  schema={schema}
                ></Input>
                <Input control={control} fieldName="phone" label={t("farm.phone")} fieldError={errors.phone} schema={schema}></Input>
                <RadioButtonGroup
                  control={control}
                  fieldName="contactType"
                  options={contactTypeOptions}
                  fieldError={errors.contactType}
                  schema={schema}
                ></RadioButtonGroup>
              </div>
            </div>
            <div className={styles.actions}>
              <DsmButton disabled={isSubmitting} onClick={handleSubmit(onSubmit)}>
                <DsmIcon name="general/save-01"></DsmIcon>
                {t("farm-create.submit")}
              </DsmButton>
              <DsmButton variant="text" destructive={true} onClick={handleCancel}>
                <DsmIcon name="general/x-circle"></DsmIcon>
                {t("general.cancel")}
              </DsmButton>
            </div>
          </>
        ) : null}
      </DsmGrid>
      {isLoading ? <DsmLoadingIndicator className="loading-indicator" size="md"></DsmLoadingIndicator> : null}
    </>
  );
}

export default FarmCreate;
