import { DsmButton, DsmEmptyState, DsmGrid, DsmLabel, DsmLoadingIndicator, DsmPagination, DsmTable } from "@dsm-dcs/design-system-react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../components/pageHeader/PageHeader";
import { useContext, useEffect, useState } from "react";
import { useLayout } from "../../contexts/layout.context";
import { routes, routeTypes } from "../../routes";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/auth.context";
import Plot from "react-plotly.js";
import { getSampleResult, getSampleResultsForCustomer } from "../../services/sample.service";
import { Role } from "../../models/enums/role.enum";
import { ComparisonType, FloatFilter, Page, SampleRequest, SortDirection, SpeciesGroup } from "../../models/API";
import { Data } from "plotly.js";
import {
  DsmLabelCloseEvent,
  DsmLabelCustomEvent,
  DsmRowActionClickEvent,
  DsmRowSelectedEvent,
  DsmSelectOption,
  DsmTableCustomEvent,
  DsmTableData
} from "@dsm-dcs/design-system";
import dayjs from "dayjs";
import styles from "./Dashboard.module.scss";
import { DashboardTableActions } from "../../models/enums/actions.enum";
import { getBoxPlotBackCss } from "../../helpers/sampleData.helper";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getPhaseForSelect, getSpecies } from "../../services/metaData.service";
import SearchInput from "../../components/filter/searchInput/SearchInput";
import FilterContainer from "../../components/filter/filterContainer/filterContainer";
import DateInput from "../../components/form/dateInput/DateInput";
import Select from "../../components/form/select/Select";
import NumberInput from "../../components/form/numberInput/NumberInput";
import { getFilterDashboardSampleRequestFormSchema } from "../../models/forms/filter-dashboard-sample-request-form.model";

function Dashboard() {
  //Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setPageTitle, setCrumbs, setToast } = useLayout();
  const { customer, role } = useContext(AuthContext);
  const [schema] = useState(getFilterDashboardSampleRequestFormSchema());
  const {
    control: filterFormControl,
    watch: filterFormWatch,
    getValues: filterFormGetValues,
    reset: filterFormReset
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema)
  });

  //State
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isRefreshingPlot, setIsRefreshingPlot] = useState<boolean>(true);
  const [plotData, setPlotData] = useState<Data[]>([]);
  const [boxPlotBackCss, setBoxPlotBackCss] = useState<string>("");
  const [selectedResults, setSelectedResults] = useState<SampleRequest[]>([]);
  const [samplesTable, setSamplesTable] = useState<DsmTableData | null>(null);
  const [samplesTablePages, setSamplesTablePages] = useState<Page[]>([]);
  const [currentSamplesTablePage, setCurrentSamplesTablePage] = useState<number>(1);
  const [phases, setPhases] = useState<DsmSelectOption[]>([]);
  const [resultFilterTypeOptions, setResultFilterTypeOptions] = useState<DsmSelectOption[]>([]);
  const filterFormSearchQuery = filterFormWatch("searchQuery");

  //Effects
  useEffect(() => {
    setPageTitle(t("dashboard.page.title"));
    setCrumbs([{ title: t("dashboard.page.title"), type: routeTypes.dashboard }]);
    initData();
    setBoxPlotBackCss(getBoxPlotBackCss());
  }, []);

  useEffect(() => {
    if (isLoading) return;
    if (currentSamplesTablePage !== 1) {
      setCurrentSamplesTablePage(1);
      return;
    }
    loadSamplesTable();
  }, [filterFormSearchQuery]);

  useEffect(() => {
    if (isLoading) return;
    loadSamplesTable();
  }, [currentSamplesTablePage]);

  useEffect(() => {
    const data: Data[] = [];
    selectedResults.forEach((sampleResult, index) => {
      data.push({
        name: `#${index + 1}`,
        type: "box",
        boxpoints: "outliers",
        y: sampleResult.samples?.map((sample) => sample.results?.[0]?.result ?? 0) ?? [],
        hoverinfo: "y"
      });
    });
    setPlotData(data);
    setIsRefreshingPlot(false);
  }, [selectedResults]);

  //Methods
  const initData = async () => {
    initFilterData();
    await loadSamplesTable(true);
    setIsLoading(false);
  };

  const loadSamplesTable = async (isInitial = false) => {
    let pageToken: string | null = null;
    if (currentSamplesTablePage) {
      pageToken = samplesTablePages.find((_) => _.page === currentSamplesTablePage - 1)?.token ?? null;
    }

    const filterValues = filterFormGetValues();
    const collectionDateStart = filterValues.collectionDateStart ? dayjs(filterValues.collectionDateStart) : undefined;
    const collectionDateEnd = filterValues.collectionDateEnd ? dayjs(filterValues.collectionDateEnd) : undefined;

    const sampleResults = await getSampleResultsForCustomer(
      role === Role.Customer ? customer?.id || "" : null,
      {
        itemsPerPage: 10,
        sortDirection: SortDirection.Desc,
        pageToken,
        filters: {
          query: filterFormSearchQuery,
          collectionDate: {
            startDate: collectionDateStart && collectionDateStart.isValid() ? collectionDateStart.format("YYYY-MM-DD") : undefined,
            endDate: collectionDateEnd && collectionDateEnd.isValid() ? collectionDateEnd.format("YYYY-MM-DD") : undefined
          },
          phaseIds: filterValues.phaseIds ? [filterValues.phaseIds] : undefined,
          average: getAverageFilterValue(filterValues.resultsType, filterValues.resultsValue) ?? {
            type: ComparisonType.GT,
            value: 0.1
          }
        }
      },
      setToast
    );

    const samplesTableData: DsmTableData = {
      columns: [
        { id: "collectionDate", label: t("sample-request.collection-date") },
        { id: "name", label: t("sample-request.name") },
        { id: "farm", label: t("sample-request.farm") },
        { id: "phase", label: t("sample-request.phase") },
        { id: "average", label: t("sample-request.average") }
      ],
      data:
        sampleResults?.rows?.map((sampleResult, index) => {
          return {
            isSelectable: true,
            isSelected:
              isInitial && role === Role.Customer && index < 3
                ? true
                : selectedResults.some((result) => result.id === sampleResult.sampleRequestId),
            id: sampleResult.sampleRequestId || "",
            actions: [
              {
                type: DashboardTableActions.Results,
                icon: "charts/bar-chart-square-01",
                title: t("dashboard.samples-table.actions.results")
              }
            ],
            cells: [
              { value: dayjs(sampleResult.collectionDate || "").format(t("general.date-format")) },
              { value: sampleResult.name || "" },
              { value: sampleResult.locationName || "" },
              { value: sampleResult.phaseName || "" },
              {
                badges: [
                  {
                    value: sampleResult.average
                      ? `${sampleResult.average?.toString() || ""} ${t("general.d3-unit")}`
                      : t("general.result-status.pending"),
                    variant: sampleResult.average ? "success" : "primary"
                  }
                ]
              }
            ]
          };
        }) || []
    };
    if (role === Role.Manager || role === Role.Admin) {
      samplesTableData.columns.splice(2, 0, { id: "customer", label: t("sample-request.customer") });
      sampleResults?.rows?.forEach((sampleResult, index) => {
        samplesTableData.data[index].cells.splice(2, 0, { value: sampleResult.customer?.name || "" });
      });
    }

    if (isInitial && role === Role.Customer) {
      const loadPromises: Promise<SampleRequest | null>[] = [];

      samplesTableData.data.forEach((row) => {
        if (row.isSelected) {
          loadPromises.push(getSampleResult(row.id, setToast));
        }
      });

      const loadedSelectedResults: SampleRequest[] = [];
      await Promise.all(loadPromises).then((results) => {
        results.forEach((sampleResult) => {
          if (sampleResult) {
            loadedSelectedResults.push(sampleResult);
          }
        });
      });
      setSelectedResults([...loadedSelectedResults]);
    }

    setSamplesTable(samplesTableData);
    setSamplesTablePages(sampleResults?.pages || []);
  };

  const initFilterData = async () => {
    const species = await getSpecies(SpeciesGroup.Swine, setToast);
    const speciesId = species.find((_) => _.group === SpeciesGroup.Swine)?.id || "";
    setPhases(await getPhaseForSelect(speciesId, setToast));

    setResultFilterTypeOptions(
      Object.keys(ComparisonType).map((comparisonType) => {
        return { value: comparisonType, text: t(`general.comparison-type.${comparisonType.toLocaleLowerCase()}`) };
      })
    );
  };

  const handleTableRowSelected = async (e: DsmTableCustomEvent<DsmRowSelectedEvent>) => {
    setIsRefreshingPlot(true);
    const previousSelectedResult = selectedResults.find((result) => result.id === e.detail.id);
    if (previousSelectedResult) {
      setSelectedResults(selectedResults.filter((result) => result.id !== e.detail.id));
      return;
    }

    const sampleResult = await getSampleResult(e.detail.id, setToast);
    if (sampleResult) {
      setSelectedResults([...selectedResults, sampleResult]);
    }
  };

  const handleLabelClose = (e: DsmLabelCustomEvent<DsmLabelCloseEvent>) => {
    setIsRefreshingPlot(true);
    const previousSelectedResult = selectedResults.find((result) => result.id === e.detail.identifier);
    if (previousSelectedResult) {
      setSelectedResults(selectedResults.filter((result) => result.id !== e.detail.identifier));
      if (samplesTable) {
        setSamplesTable({
          ...samplesTable,
          data: samplesTable.data.map((row) => (row.id === previousSelectedResult.id ? { ...row, isSelected: false } : row))
        });
      }
    }
  };

  const handleResultListPageChange = async (page: number) => {
    setCurrentSamplesTablePage(page);
  };

  const handleTableAction = (e: DsmTableCustomEvent<DsmRowActionClickEvent>) => {
    if (e.detail.action === DashboardTableActions.Results) {
      navigate(routes.farmSample.replace(":sampleID", e.detail.id));
    }
  };

  const handleApplyFilters = () => {
    loadSamplesTable();
  };

  const handleClearFilters = () => {
    filterFormReset();
  };

  const getAverageFilterValue = (resultsType: ComparisonType | undefined, resultsValue: number | undefined): FloatFilter | undefined => {
    if (resultsType && resultsValue) {
      return { type: resultsType, value: resultsValue };
    }

    return { type: ComparisonType.GT, value: 0.1 };
  };

  const handleClearAll = () => {
    setIsRefreshingPlot(true);
    setSelectedResults([]);
    if (samplesTable) {
      setSamplesTable({
        ...samplesTable,
        data: samplesTable.data.map((row) => ({ ...row, isSelected: false }))
      });
    }
  };

  return (
    <>
      <style>{boxPlotBackCss}</style>
      <DsmGrid
        className={`main-container main-container--with-breadcrumb grid-refresh ${isRefreshingPlot ? "grid-refresh__refreshing" : ""}`}
        fixed={true}
        container-only={true}
      >
        <PageHeader header={t("dashboard.page.title")} description={t("dashboard.page.description")}></PageHeader>
        {!isLoading ? (
          <>
            <div className={`panel ${styles["graph-panel"]}`}>
              {!isRefreshingPlot && selectedResults.length === 0 ? (
                <div className={styles["box-plot__empty-text"]}>{t("dashboard.select-results")}</div>
              ) : null}
              <Plot
                className={`box-plot ${styles["box-plot"]} ${
                  !isRefreshingPlot && selectedResults.length === 0 ? styles["box-plot__empty"] : ""
                }`}
                data={plotData}
                layout={{
                  dragmode: false,
                  yaxis: {
                    range: [0, 101],
                    dtick: 10,
                    title: t("dashboard.boxplot.yaxis")
                  },
                  xaxis: { type: "category" },
                  margin: { t: 40, b: 40, r: 40, l: 70 },
                  showlegend: false,
                  hoverlabel: {
                    font: { family: '"DM Sans", sans-serif' }
                  },
                  font: {
                    family: '"DM Sans", sans-serif'
                  }
                }}
                config={{ staticPlot: false, responsive: true, displayModeBar: false, doubleClick: false, scrollZoom: false }}
                useResizeHandler={true}
                style={{ width: "100%", height: "530px" }}
              />
              <DsmLoadingIndicator size="lg" className="grid-refresh__loader"></DsmLoadingIndicator>
            </div>
            <div className={styles["selected-samples-header"]}>
              <h3>{t("dashboard.selected-header")}</h3>
              {selectedResults.length > 0 ? (
                <DsmButton variant="text" onClick={handleClearAll}>
                  {t("dashboard.clear-all")}
                </DsmButton>
              ) : null}
            </div>
            <div className={styles["selected-samples"]}>
              {selectedResults.map((sampleResult, index) => (
                <DsmLabel key={sampleResult.id} identifier={sampleResult.id} closeable={true} onDsmClosed={handleLabelClose}>
                  #{index + 1} {sampleResult.name}
                </DsmLabel>
              ))}
              {selectedResults.length === 0 ? <span>{t("dashboard.no-selected")}</span> : null}
            </div>
            <div className="filters">
              <div className="search">
                <SearchInput fieldName="searchQuery" control={filterFormControl} placeholder={t("general.search")}></SearchInput>
              </div>
              <FilterContainer onApply={handleApplyFilters} onClear={handleClearFilters}>
                <>
                  <div className="filter-row__two-items">
                    <DateInput
                      fieldName="collectionDateStart"
                      control={filterFormControl}
                      label={t("sample-request.collection-date-start")}
                    ></DateInput>
                    <DateInput
                      fieldName="collectionDateEnd"
                      control={filterFormControl}
                      label={t("sample-request.collection-date-end")}
                    ></DateInput>
                  </div>
                  <Select fieldName="phaseIds" control={filterFormControl} options={phases} label={t("sample-request.phase")}></Select>
                  <div className="filter-row__two-items">
                    <Select
                      fieldName="resultsType"
                      control={filterFormControl}
                      options={resultFilterTypeOptions}
                      label={t("sample-request.result-type")}
                    ></Select>
                    <NumberInput
                      fieldName="resultsValue"
                      control={filterFormControl}
                      label={t("sample-request.result-value")}
                    ></NumberInput>
                  </div>
                </>
              </FilterContainer>
            </div>
            <div className="card card--table">
              {samplesTable?.data.length ? (
                <>
                  <DsmTable
                    data={samplesTable}
                    onDsmRowSelected={handleTableRowSelected}
                    onDsmRowActionClick={handleTableAction}
                  ></DsmTable>
                  {samplesTablePages.length > 0 ? (
                    <DsmPagination
                      currentPage={currentSamplesTablePage}
                      pageCount={samplesTablePages.length}
                      onDsmChangePage={(e) => handleResultListPageChange(e.detail)}
                    ></DsmPagination>
                  ) : null}
                </>
              ) : (
                <DsmEmptyState icon="charts/pie-chart-01" header={t("dashboard.samples-table.empty")}></DsmEmptyState>
              )}
            </div>
          </>
        ) : (
          <DsmLoadingIndicator className="loading-indicator" size="md"></DsmLoadingIndicator>
        )}
      </DsmGrid>
    </>
  );
}

export default Dashboard;
